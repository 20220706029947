<template>
  <vx-card style="padding-left: 10px">
    <div class="vx-col lg:w-1/2" align="left"></div>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" :sst="true" :total="totalDocs" :data="orderData" @search="handleSearch"
        @change-page="handleChangePage" @sort="handleSort" :max-items="dataTableParams.limit" search
        :noDataText="noDataText">
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in limitOptions"
                :clearable="false" />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Order ID</vs-th>
          <vs-th>Clinic</vs-th>
          <vs-th>Date Placed</vs-th>
          <vs-th>Time Placed</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Order Total</vs-th>
          <vs-th ><div class=" text-center w-full"><span>Actions</span></div></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].supplierOrders">
              <div v-if="data[indextr].supplierOrders.length > 0">
                <div v-for="item in data[indextr].supplierOrders" v-bind:key="item">
                  {{ item.order.orderNumber }}
                </div>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].shippingDetails.companyName">
              {{
                  data[indextr].shippingDetails.companyName
                    ? data[indextr].shippingDetails.companyName
                    : "NA"
              }}
            </vs-td>
            <vs-td>
              {{ moment(data[indextr].createdAt).format("DD MMM YYYY") }}
            </vs-td>
            <vs-td>
              {{ getTime(data[indextr].createdAt) }}
            </vs-td>
            <vs-td :data="data[indextr].status">
              {{ getStatus(data[indextr]) }}
            </vs-td>
            <vs-td :data="data[indextr].total">
              ${{ data[indextr].total | formatDecimals }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <div class="flex flex-no-wrap" style="justify-content: flex-end;">
                <vx-tooltip text="View Order Details">
                  <a v-if="getPdf(data[indextr]) !== '#'" :href="getPdf(data[indextr])" :download="getName(data[indextr])" target="_blank">
                    <vs-button type="flat" style="float: left" size="small" color="success" class="m-1">Invoice PDF
                    </vs-button>
                  </a>
                </vx-tooltip>
                <vx-tooltip text="View Order Details" position="left">
                  <vs-button type="border" size="small" @click="viewOrderDetails(data[indextr]._id)" icon-pack="feather"
                    icon="icon-edit" class="mr-2" color="success"></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Regenerate PDF" position="left">
                  <vs-button type="border" size="small" @click="generateAttachment(data[indextr]._id)"
                    icon-pack="feather" icon="icon-refresh-cw" class="mr-2" color="success"></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Complete Payment" position="left">
                  <vs-button type="border" size="small" @click="generate3ds(data[indextr]._id)"
                    icon-pack="feather" icon="icon-check" class="mr-2" color="success"></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div v-if="displayAll"
        :style="{ display: 'flex', justifyContent: 'end' }">
        <span class="mr-2"></span>
        <pagination
          v-if="serverResponded"
          :hasPrev="hasPrev" :hasNext="hasNext"
          :style="{ marginTop: '5px' }"
          @handleOnChangePage="handleChangePage"/>
      </div>
      <template v-else>
        <span class="mr-2">
          {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="9"
            :prevText="'<'" :nextText="'>'"></paginate>
        </div>
      </template>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import { mapActions } from "vuex";
import Pagination from "../../../general-component/pagination.vue";

export default {
  name: "OrdersList",
  components: {
    "v-select": vSelect,
    Pagination
  },
  props: [
    "orderData",
    "totalDocs",
    "page",
    "noDataText",
    "displayAll",
    "hasNext",
    "hasPrev"
  ],
  data() {
    return {
      isMounted: false,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("storeOrder", ["getOrderDetails", "getOrderDetailsIntent"]),
    moment(date) {
      return moment(date);
    },
    getTime(date) {
      return `${moment(date).format('HH:mm')} AEDT`;
    },
    async generate3ds(row) {
      // Get the order details to fetch intent
      const {data:{data:{result}}} = await this.getOrderDetails(row);

      // Check if there is an order
      if(!result.length) {
        return;
      }

      // Get the existing order
      const order = result.pop();

      // Check if there is an intentID
      if(!order.intentId) {
        return;
      }

      // Pull out the intent information
      const {data:{data:{result:{client_secret}}}} = await this.getOrderDetailsIntent(order.intentId);

      // Check if if intent client_secret is existing
      if(!client_secret){
        return;
      }

      // Retrieve payment intent
      const { paymentIntent } = await this.stripe.retrievePaymentIntent(client_secret);

      // For confirmation
      if(paymentIntent.status == "requires_confirmation") {
        const confirmCardPayment = await this.stripe.confirmCardPayment(client_secret, {
          payment_method: paymentIntent.payment_method,
        });
        return;
      }

      // Now check if the payment needs an action
      if(paymentIntent.next_action && paymentIntent.status == "requires_action") {
        // Create the confirmation
        console.log(paymentIntent)
        const action = paymentIntent.next_action;
        if (action && action.type === 'redirect_to_url') {
          window.location = action.redirect_to_url.url;
          return;
        }
        if (action && action.type === "use_stripe_sdk") {
          const confirmCardPayment = await this.stripe.confirmCardPayment(client_secret, {
            payment_method: paymentIntent.payment_method,
          });
          return;
        }
      }
    },
    getPdf(row) {
      if (row.orderinvoices) {
        return row.orderinvoices.pdfLink;
      }
      return "#";
    },
    getName(row) {
      const companyName = row.shippingDetails.companyName
        ? row.shippingDetails.companyName
        : "NA";

      if(row.orderinvoices) {
        return `${row.orderinvoices.invoiceNumber} ${companyName}`;
      }
      return "#";
    },
    async fetchList() {
      this.$vs.loading();
      await this.$emit("fetchList", this.dataTableParams);
      this.serverResponded = true;
      this.$vs.loading.close();
    },
    getStatus(item) {
      if (item.isPaymentTerms && item.status !== "Paid") {
        return "Payment Terms"
      }
      return item.status;
      // if (item.paymentType === "ClinicBankAccount") {
      //   if (item.status === "On hold") return "Processing";
      //   else if (item.status === "Failed") return "Failed";
      //   else if (item.status === "Payment-Failed") return "Payment-Failed";
      //   else if (
      //     item.status === "Paid" &&
      //     item.trackingNumbers &&
      //     item.trackingNumbers.length
      //   )
      //     return "Sent";
      //   else return "Paid";
      // } else {
      //   if (item.trackingNumbers && item.trackingNumbers.length) return "Sent";
      //   else return "Paid";
      // }
    },
    viewOrderDetails(id) {
      this.$emit("viewOrderDetail", id);
    },
    async generateAttachment(id) {
      await this.$emit("regenerateAttachment", id);
      await this.$emit("fetchList", this.dataTableParams);
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      if (typeof page === 'string' && this.displayAll) {
        let payload = page === 'next' ? payload = {
          next: true,
          id: this.orderData[this.orderData.length - 1]._id,
          ...this.dataTableParams
        } : payload = {
          prev: true,
          id: this.orderData[0]._id,
          ...this.dataTableParams
        }

        this.$emit("fetchList", payload);
        return;
      }

      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  mounted() {
    this.stripe = window.Stripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    );
  },
  created() {
    this.noDataText = "Loading...";
    this.fetchList();
  },
};
</script>
